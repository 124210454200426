import YAML from 'yaml';

let remoteCache = {};

const fetchGovRepoFile = async (path) => {
  return await (await fetch(`https://raw.githack.com/alphagov/collections/master/${path}`)).text();
};

const cacheWrapper = async (key, func) => {
  const cached = remoteCache[key];

  if (cached) return cached;

  const result = await func();

  remoteCache[key] = result;

  return result;
};


/*const getGovLocale = async (lang) => {
  return await cacheWrapper(`locale_${lang}`, async () => await _getGovLocale(lang));
};

const _getGovLocale = async (lang) => {
  const rawYaml = await fetchGovRepoFile(`config/locales/${lang}/lookup.yml`);

  return YAML.parse(rawYaml);
};*/


const getLocalRestrictions = async () => {
  return await cacheWrapper(`restrictions`, async () => await _getLocalRestrictions());
};

const _getLocalRestrictions = async () => {
  const rawYaml = await fetchGovRepoFile(`lib/local_restrictions/local-restrictions.yaml`);

  return YAML.parse(rawYaml);
};

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

window.onload = async () => {
  const restrictions = await getLocalRestrictions();
  
  const councils = Object.values(restrictions).map((x) => x.name);

  console.log(councils);

  const el = document.getElementById('coreInput');
  autocomplete(el, councils);

  const rLevel = document.getElementById('rLevel');
  const rSince = document.getElementById('rSince');

  const tryMatch = () => {
    const match = Object.values(restrictions).find((x) => x.name.toLowerCase() === el.value.toLowerCase());

    if (!match) {
      rLevel.innerHTML = `&nbsp;`;
      rSince.innerHTML = `&nbsp;`;

      return;
    }

    console.log(match);

    const [ { alert_level, start_date, start_time } ] = match.restrictions;

    const d = new Date(start_date);
    
    let suffix = 'th';

    switch ([...(d.getDate().toString())].pop()) {
      case '1':
        suffix = 'st';
        break;
      
      case '2':
        suffix = 'nd';
        break;
      
      case '3':
        suffix = 'rd';
        break;
    }

    rLevel.innerHTML = `Tier <strong>${alert_level}</strong>`;
    rSince.innerHTML = `From <strong>${monthNames[d.getMonth()]} ${d.getDate()}${suffix}</strong>`;
  };

  let lastValue = '';

  const checkValueChange = () => {
    const currentValue = el.value;

    if (lastValue !== currentValue) tryMatch();

    lastValue = currentValue;
  };

  setInterval(checkValueChange, 100);

  /*el.oninput = tryMatch;
  e.onclickdow = tryMatch;*/

  console.log('done');
};


function autocomplete(inp, arr) {
  /*the autocomplete function takes two arguments,
  the text field element and an array of possible autocompleted values:*/
  var currentFocus;
  /*execute a function when someone writes in the text field:*/
  inp.addEventListener("input", function(e) {
      var a, b, i, val = this.value;
      /*close any already open lists of autocompleted values*/
      closeAllLists();
      if (!val) { return false;}
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      a.setAttribute("id", this.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");
      /*append the DIV element as a child of the autocomplete container:*/
      this.parentNode.appendChild(a);
      /*for each item in the array...*/
      //console.log(val.toLowerCase(), arr[0].toLowerCase(), arr[0].toLowerCase().includes(val.toLowerCase()));
      let m = 0;
      for (i = 0; i < arr.length; i++) {
        /*check if the item starts with the same letters as the text field value:*/
        if (m < 50 && arr[i].toLowerCase().includes(val.toLowerCase())) {
          /*create a DIV element for each matching element:*/
          b = document.createElement("DIV");
          /*make the matching letters bold:*/
          b.innerHTML = arr[i];
          //b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
          //b.innerHTML += arr[i].substr(val.length);
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
              b.addEventListener("click", function(e) {
              /*insert the value for the autocomplete text field:*/
              inp.value = this.getElementsByTagName("input")[0].value;
              /*close the list of autocompleted values,
              (or any other open lists of autocompleted values:*/
              closeAllLists();
          });
          a.appendChild(b);

          m++;
        }
      }
  });
  /*execute a function presses a key on the keyboard:*/
  inp.addEventListener("keydown", function(e) {
      var x = document.getElementById(this.id + "autocomplete-list");
      if (x) x = x.getElementsByTagName("div");
      if (e.keyCode == 40) {
        /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        currentFocus++;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 38) { //up
        /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        currentFocus--;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[currentFocus].click();
        }
      }
  });
  function addActive(x) {
    /*a function to classify an item as "active":*/
    if (!x) return false;
    /*start by removing the "active" class on all items:*/
    removeActive(x);
    if (currentFocus >= x.length) currentFocus = 0;
    if (currentFocus < 0) currentFocus = (x.length - 1);
    /*add class "autocomplete-active":*/
    x[currentFocus].classList.add("autocomplete-active");
  }
  function removeActive(x) {
    /*a function to remove the "active" class from all autocomplete items:*/
    for (var i = 0; i < x.length; i++) {
      x[i].classList.remove("autocomplete-active");
    }
  }
  function closeAllLists(elmnt) {
    /*close all autocomplete lists in the document,
    except the one passed as an argument:*/
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      if (elmnt != x[i] && elmnt != inp) {
      x[i].parentNode.removeChild(x[i]);
    }
  }
}
/*execute a function when someone clicks in the document:*/
document.addEventListener("click", function (e) {
    closeAllLists(e.target);
});
}